import React, { useState } from 'react';

const defaultValue = false;
const SideNavigationContext = React.createContext();

export function SideNavigationContextProvider({ children, initValue }) {
    const [isVisible, setIsVisible] = useState(initValue | defaultValue);

    return <SideNavigationContext.Provider value={{ visible: isVisible, updateVisibility: setIsVisible }}>
        {children}
    </SideNavigationContext.Provider>
}

export const useSideNavigationContext = () => React.useContext(SideNavigationContext);