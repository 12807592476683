import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ lang, title, keywords, description, url }) {
  const { site } = useStaticQuery(
    graphql`
       query {
         site {
           siteMetadata {
             site_name
             siteUrl
           }
         }
       }
     `
  );
  const pageUrl = url ? site.siteMetadata.siteUrl + url : site.siteMetadata.siteUrl

  return (
    <Helmet htmlAttributes={{ lang: lang }}>
      <meta property="og:title" content={title} />
      <meta property="keywords" content={keywords} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={site.siteMetadata.site_name} />
      <meta property="og:url" itemprop="url" content={pageUrl}></meta>

      <meta name="title" content={title} />
      <meta name="keywords" content={keywords} />
      <meta name="type" content="website" />
      <meta name="description" content={description} />
      <meta name="site_name" content={site.siteMetadata.site_name} />
      <meta name="url" content={pageUrl}></meta>
      <title>{title}</title>
    </Helmet>
  )
}

SEO.propTypes = {
  lang: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  keywords: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

export default SEO