import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/shell/Layout";
import { LanguageContextProvider } from "../components/shell/LanguageContext";

export default function LayoutWrapper(props) {
  const data = useStaticQuery(graphql`
    {
      allCommonJson {
        nodes {
          language
          logo {
            alt
            src {
              childImageSharp {
                fixed {
                  src
                }
              }
            }
          }
          contactInformation {
            title
            information {
              id
              sort
              type
              description
            }
          }
          navigationInformation {
            navigation {
              id
              title
              sort
              link
            }
            title
          }
          socialNetwork {
            id
            link
            sort
            title
            type
          }
        }
      }
    }
  `);

  const dataByLanguage = data.allCommonJson.nodes.find(n => n.language === props.pageContext.language);

  return <Layout {...dataByLanguage}>
    <LanguageContextProvider>
      {props.children}
    </LanguageContextProvider>
  </Layout>
}