import React from "react";
import Img from "gatsby-image"

export function getImg(src) {
    if (src.publicURL) return src.publicURL;

    if (src.childImageSharp) {
        if (src.childImageSharp.fixed) return src.childImageSharp.fixed.src;
        return src.src.childImageSharp.fluid;
    }

    const results = src.split("/");
    const imgFileName = results[results.length - 1];
    const category = results[results.length - 2];
    switch (category) {
        case "home":
        case "news":
        case "products":
        case "productDetail":
        case "aboutUs":
        case "common":
        case "category":
            return validateUrl(category, imgFileName);
        default:
            return src;
    }
}

function validateUrl(category, imgFileName) {
    let result = require("../../images/" + category + "/" + imgFileName);
    if (typeof result === "string" && result.indexOf("static") !== -1) return result;
    return result.default ? result.default : result;
}

export function ResponsiveImage({ largeImage, mediumImage, smallImage }) {
    return <img src={getImg(largeImage.src)}
        srcSet={`${getImg(smallImage ? smallImage.src : mediumImage.src)} 300w, ${getImg(mediumImage.src)} 768w, ${getImg(largeImage.src)} 1280w, ${getImg(largeImage.src)} 3200w`} />
}

export default function Image(props) {
    if (props.src.childImageSharp) {
        if (props.src.childImageSharp.fixed) {
            return <img
                className={props.className}
                id={props.id}
                src={getImg(props.src)}
                alt={props.alt}
            ></img>
        }
        return <Img
            className={props.className}
            id={props.id}
            fluid={props.src.childImageSharp.fluid}
            atl={props.alt}
        />
    } else if (typeof props.src === "string" && props.src.indexOf("static") === -1) {
        return <img
            className={props.className}
            id={props.id}
            src={getImg(props.src)}
            alt={props.alt}
        ></img>
    } else {
        return <img {...props}></img>
    }
}