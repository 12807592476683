import React from "react";
import { useI18next } from "gatsby-plugin-react-i18next";

import EnglishFlag from "../../images/common/us-flag-icon.png";
import JapaneseFlag from "../../images/common/jp-flag-icon.png";

import "./LanguageSwitcher.css";
import Image from "./Image";
import ALink from "./Alink";

const languageData = [
    {
        id: "jp",
        icon: {
            src: JapaneseFlag,
            alt: "jp"
        },
        text: {
            jp: "日本語",
            en: "jp"
        }
    },
    {
        id: "en",
        icon: {
            src: EnglishFlag,
            alt: "en"
        },
        text: {
            jp: "英語",
            en: "en"
        }
    }
];

export default function LanguageSwitcher(props) {
    const { changeLanguage, language, languages, originalPath } = useI18next();

    function onChangeLanguage(param) {
        changeLanguage(param.id);
    }

    return <div className="language-switcher">
        {
            languageData.map((l, key) => {
                return <React.Fragment key={key}>
                    <ALink to={originalPath} language={l.id} onClick={() => onChangeLanguage(l)} >
                        <div className={`language-switcher__item ${l.id === language ? "active-language" : ""}`}>
                            <div className="language-switcher__icon">
                                <Image {...l.icon} />
                            </div>
                        </div>
                    </ALink>
                </React.Fragment>
            })
        }
    </div>
}