import React from "react";

import Image from "../merged/Image";
import HamburgerButton from "./HamburgerButton";
import HeaderNavigation from "./HeaderNavigation";
import LanguageSwitcher from "../merged/LanguageSwitcher";
import ALink from "../merged/Alink";
import "./Header.css";

export default function Header({ logo, navigation }) {
    return <header className="header">
        <div className="header__content">
            <ALink to="/">
                <div className="header__logo">
                    <Image {...logo} className="header__logo-img" />
                </div>
            </ALink>
            <HeaderNavigation data={navigation} />
            <div className="header__controls">
                <div className="header__control-item">
                    <LanguageSwitcher />
                </div>
                <div className="header__control-item">
                    <HamburgerButton />
                </div>
            </div>
        </div>
    </header>
}