import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageContext = React.createContext({});

export function LanguageContextProvider(props) {
    const [, i18n] = useTranslation();

    return (
        <LanguageContext.Provider value={{ language: i18n.language }}>
            {props.children}
        </LanguageContext.Provider>
    );
}

export const useLanguageContext = () => React.useContext(LanguageContext);