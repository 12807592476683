import React from "react";

import BaseLine from "../shared/BaseLine";
import Footer from "./Footer";
import Header from "./Header";
import SideNavigation from "./SideNavigation";
import TopButton from "./TopButton";
import "./Layout.css";
import { SideNavigationContextProvider } from "./SideNavigationContext";

export default function Layout({ children, navigationInformation, contactInformation, socialNetwork, logo }) {
    return <React.Fragment>
        <SideNavigationContextProvider initValue={false}>
            <Header logo={logo} navigation={navigationInformation.navigation} />
            <SideNavigation data={navigationInformation.navigation} />
        </SideNavigationContextProvider>
        <BaseLine />
        <main className="page__content">
            {children}
        </main>
        <Footer navigationData={navigationInformation}
            contactData={contactInformation}
            socialNetworkData={socialNetwork} />
        <TopButton />
    </React.Fragment>
}