import React, { useEffect, useRef } from "react";

import "./HamburgerButton.css";
import { useSideNavigationContext } from "./SideNavigationContext";

const visibleCssClass = 'opened';

export default function HamburgerButton({ width, height }) {
    const { visible, updateVisibility } = useSideNavigationContext();
    const hamburgerButtonRef = useRef();

    useEffect(() => {
        const buttonElement = hamburgerButtonRef.current;

        if (!buttonElement) return;

        if (visible) {
            !buttonElement.classList.contains(visibleCssClass) && buttonElement.classList.add(visibleCssClass);
        } else {
            buttonElement.classList.contains(visibleCssClass) && buttonElement.classList.remove(visibleCssClass);
        }

        buttonElement.setAttribute('aria-expanded', buttonElement.classList.contains(visibleCssClass));
    }, [visible]);

    function onClick(e) {
        e.stopPropagation()
        updateVisibility(!visible);
    }

    return <button
        className="hamburger-wrapper__button"
        ref={hamburgerButtonRef}
        onClick={onClick}
        aria-label="Main Menu">
        <svg width={width || 50} height={height || 50} viewBox="0 0 100 100">
            <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
            <path className="line line2" d="M 20,50 H 80" />
            <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
        </svg>
    </button>
}