import React, { useEffect, useRef } from "react";

import "./TopButton.css";

export default function TopButton() {
    const topButtonRef = useRef();

    function scrollToTop() {
        if (typeof window === undefined) return;

        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    useEffect(() => {
        if (typeof window === undefined) return;

        document.addEventListener('scroll', function (e) {
            const topButtonElement = topButtonRef.current;

            if (!topButtonElement) return;

            if (document.documentElement.scrollTop > 300 || document.body.scrollTop > 300) {
                topButtonElement.classList.add('show');
            } else {
                topButtonElement.classList.remove('show');
            }
        });
    })

    return <a id="top-button" ref={topButtonRef} onClick={scrollToTop}></a>
}