import React from "react";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FiMapPin, FiMail, FiPhone } from "react-icons/fi";

import ALink from "../merged/Alink";
import { TinyTitle } from "../shared/FontCollection";
import "./Footer.css";

function FooterNavigation({ data }) {
    return <div className="footer__navigation">
        <div className="footer__navigation__title">
            <TinyTitle>{data.title}</TinyTitle>
        </div>
        <nav className="footer-navigation__menu">
            {
                data.navigation.map(item => {
                    return <div key={item.id} className="footer-navigation____menu-item">
                        <ALink to={item.link} aria-label="Social network link" target="_blank" rel="noopener noreferrer">{item.title}</ALink>
                    </div>
                })
            }
        </nav>
    </div>
}

function getIcon(type) {
    switch (type) {
        case "address":
            return <FiMapPin size={16} />
        case "phone":
            return <FiPhone size={16} />
        default:
            return <FiMail size={16} />
    }
}

function ContactItem({ data }) {
    return <div className="footer__contact-info-row">
        <div className="footer__contact-info-icon">
            {getIcon(data.type)}
        </div>
        <div className="footer__contact-info-text"> {data.description}</div>
    </div>
}

function FooterContact({ data }) {
    return <div className="footer__contact">
        <div className="footer__contact-title">
            <TinyTitle>{data.title.toUpperCase()}</TinyTitle>
        </div>
        <div className="footer__contact-info">
            {
                data.information.map(item => {
                    return <ContactItem key={item.id} data={item} />
                })
            }
        </div>
    </div>
}

function SNIcon({ type }) {
    switch (type) {
        case "facebook":
            return <FaFacebookF size={32} />;
        case "instagram":
            return <FaInstagram size={32} />;
        default:
            return null;
    }
}

function FooterSN({ data }) {
    return <div className="footer__sn">
        <div className="footer__sn-icons">
            {
                data.map(item => {
                    return <div key={item.id} className="footer__sn-icon">
                        <a href={item.link}
                            target="_blank"
                            rel="noopener noreferrer">
                            <SNIcon type={item.type} />
                        </a>
                    </div>
                })
            }
        </div>
    </div>
}

export default function Footer({ navigationData, contactData, socialNetworkData }) {
    return <footer className="footer">
        <div className="footer__content">
            <div className="footer__content-item footer__content-item--first">
                <FooterNavigation data={navigationData} />
            </div>
            <div className="footer__content-item footer__content-item--second">
                <FooterContact data={contactData} />
            </div>
            <div className="footer__content-item  footer__content-item--third">
                <FooterSN data={socialNetworkData} />
            </div>
        </div>
    </footer>
}