import React from "react";

import ALink from "../merged/Alink";
import "./SideNavigation.css"
import { useSideNavigationContext } from "./SideNavigationContext";

export default function SideNavigation({ data }) {
    const { visible, updateVisibility } = useSideNavigationContext();

    return <div className="side-navigation__wrapper">
        <nav className={`side-navigation ${visible ? "side-navigation--open" : ""}`}>
            <div className="side-navigation__content">
                {
                    data.map(item => {
                        return <div key={item.id} className="side-navigation__menu-item">
                            <ALink to={item.link} onClick={() => updateVisibility(false)}>{item.title}</ALink>
                        </div>
                    })
                }
            </div>
        </nav>
    </div>
}