import React from "react";

import "./FontCollection.css";

export function ExtraLargeTitle(props) {
    return <h1 className="h1">{props.children}</h1>
}

export function LargeTitle(props) {
    return <h2 className="h2">{props.children}</h2>
}

export function MediumTitle(props) {
    return <h3 className="h3">{props.children}</h3>
}

export function SmallTitle(props) {
    return <h4 className="h4">{props.children}</h4>
}

export function TinyTitle(props) {
    return <h5 className="h5">{props.children}</h5>
}

export function ErrorMessage(props) {
    return <span className="span">{props.children}</span>
}

export function Text(props) {
    return <p className="p">{props.children}</p>
}