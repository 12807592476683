import React from "react";

import ALink from "../merged/Alink";
import { Text } from "../shared/FontCollection";
import "./HeaderNavigation.css";

export default function HeaderNavigation({ data }) {
    return <nav className="header-navigation">
        <div className="header-navigation__content">
            {
                data.map(item => {
                    return <div key={item.id} className="header-navigation____menu-item">
                        <ALink to={item.link}><Text>{item.title}</Text></ALink>
                    </div>
                })
            }
        </div>
    </nav>
}